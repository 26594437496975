<template>
  <footer class="footer text-white" style="background-color: green">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() }} © Go I.</div>
        <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            Desenvolvido por Go I Assessoria Digital e Desenvolvimento
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
