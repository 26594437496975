<template>
  <header id="page-topbar">
    <AccountModal
      v-if="showAccountModal"
      @closeModal="showAccountModal = !showAccountModal"
    />
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <router-link to="/home" class="logo logo-dark" title="Home">
            <span class="logo-sm">
              <img src="@/assets/images/logo/logo-sm-dark.png" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo/logo-dark.png" height="50" />
            </span>
          </router-link>
          <router-link to="/home" class="logo logo-light" title="Home">
            <span class="logo-sm">
              <img src="@/assets/images/logo/logo-sm-light.png" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo/logo-light.png" height="20" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
          data-toggle="collapse"
          title="Expandir/Esconder o Menu"
          @click="toggleMenu"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>
      </div>

      <div class="d-flex" v-if="isLoggedIn">
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block"
        >
          <template v-slot:button-content>
            <span class="d-xl-inline-block ml-1">{{ userName }}</span>
            <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
          </template>
          <span
            class="dropdown-item"
            title="Minha Conta"
            role="button"
            @click="showAccountModal = true"
          >
            <i class="ri-user-line align-middle mr-1"></i>
            Alterar senha
          </span>
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item"
            title="Minha Conta"
            role="button"
            href="https://goidigital.com.br/construindosonhos/politica_privacidade.pdf"
            target="_blank"
          >
            <i class="ri-file-paper-2-line align-middle mr-1"></i>
            Política de privacidade
          </a>
          <div class="dropdown-divider"></div>
          <span
            class="dropdown-item text-danger"
            title="Sair"
            role="button"
            @click="logout"
          >
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            Sair
          </span>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import PATHS from '@/router/routesMap'
import AccountModal from '@/components/account/AccountModal.vue'

export default {
  name: 'TopbarHorizontal',

  components: {
    AccountModal,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showAccountModal: false,
    }
  },

  computed: {
    userName() {
      return this.$store.getters.user.name
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
  },

  methods: {
    toggleMenu() {
      const element = document.getElementById('topnav-menu-content')
      element.classList.toggle('show')
    },

    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push(PATHS.BASE_URL)
      })
    },
  },
}
</script>
